import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Currency from "../../components/common/currency";
import { removeFromCart, incrementQty, decrementQty } from "../../actions";
import { getCartTotal, getCouponAmount } from "../../services";
import CartHeader from "./CartHeader";
import { loadBusiness } from "../../api/products";
import store from "../../store";
import { getBusiness } from "../../actions";
import Coupon from "../Coupon";
import { setShipping } from "../../actions";
import Snackbar from "@material-ui/core/Snackbar";

function CartPage(props) {
    const [message, setMessage] = useState("");
    useEffect(() => {
        async function init() {
            let pathname = props.location.pathname;
            let uid = pathname.split("/")[1];
            let business = await loadBusiness(uid);
            store.dispatch(getBusiness(business));
        }
        if (!props.business.uid) {
            init();
        }
    }, [props.business.uid, props.location.pathname]);

    function decrement(item) {
        if (item.qty === 1) {
            props.removeFromCart(item);
        } else {
            props.decrementQty(item);
        }
        props.setShipping({});
    }

    function increment(item) {
        if (showStockQuantity() && item.qty >= item.quantity) {
            return;
        }
        props.incrementQty(item, 1);
        props.setShipping({});
    }

    function checkout() {
        if (props.business.minimumOrder && props.business.minimumOrder > 0) {
            if (props.total < props.business.minimumOrder) {
                setMessage(
                    `O pedido mínimo é de R$ ${props.business.minimumOrder}. Qualquer dúvida entre em contato conosco.`
                );
                return;
            }
        }
        props.history.push({
            pathname: "/checkout",
        });
    }

    function showStockQuantity() {
        let show = false;
        if (
            props.business.activePlanName &&
            props.business.activePlanName !== "Gratuito" &&
            props.business.features &&
            props.business.features.includes("stock")
        ) {
            show = true;
        }

        return show;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleClose() {
        setMessage("");
    }

    return (
        <div id="cart-minimalist-wrap">
            <div class="cart-header-minimalist">
                <CartHeader />
            </div>

            {props.cartItems.length > 0 ? (
                <>
                    <div class="cart-items-minimalist">
                        {props.cartItems.map((item) => {
                            return (
                                <>
                                    <div class="item">
                                        <div class="item-picture">
                                            <img
                                                src={item.img}
                                                alt="img1"
                                                class="item-picture-image"
                                            />
                                        </div>
                                        <div class="item-description">
                                            <div class="item-name">
                                                {item.name}{" "}
                                                {(item.color || item.size) && " - "}
                                                {item.color} {item.size}
                                            </div>
                                            {item.variationValue && (
                                                <div class="item-variation">
                                                    {item.variationValue}
                                                </div>
                                            )}
                                            {item.promoPrice ? (
                                                <div style={{ display: "flex" }}>
                                                    <div className="item-price-line">
                                                        <Currency value={item.price} />
                                                    </div>
                                                    <div className="item-promoprice">
                                                        <Currency
                                                            value={item.promoPrice}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div class="item-price">
                                                    <Currency value={item.price} />
                                                </div>
                                            )}

                                            <div class="item-quantity">
                                                <button
                                                    class="minus"
                                                    onClick={() => decrement(item)}
                                                >
                                                    -
                                                </button>
                                                <div class="qty">{item.qty}</div>
                                                <button
                                                    class="add"
                                                    onClick={() => increment(item)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            {showStockQuantity() && (
                                                <div
                                                    style={{ fontSize: 14, marginTop: 5 }}
                                                >
                                                    Disponível: {item.quantity}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                </>
                            );
                        })}
                    </div>

                    <div class="cart-shipping-minimalist">
                        
                    </div>

                    <Coupon />

                    <div class="cart-total-minimalist">
                        <div>
                            {props.couponAmount && <p>Sub Total</p>}
                            {props.couponAmount && (
                                <p>Cupom de desconto ({props.coupon.amount}%)</p>
                            )}
                            
                            <p>Total</p>
                        </div>
                        <div>
                            {props.couponAmount && (
                                <p>
                                    <Currency value={props.subtotal} />
                                </p>
                            )}
                            {props.couponAmount && (
                                <p>
                                    <Currency value={props.couponAmount} />
                                </p>
                            )}
                           
                            <p>
                                <Currency value={props.total} />
                            </p>
                        </div>
                    </div>

                    <div onClick={() => checkout()} class="cart-button-minimalist">
                        FECHAR PEDIDO
                    </div>
                    <Snackbar
                        open={message !== ""}
                        //onClose={handleClose}
                        message={message}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        autoHideDuration={3000}
                        onClose={handleClose}
                    />
                </>
            ) : (
                <>
                    <div class="cart-items-minimalist">
                        <div class="empty-cart-container">
                            <img
                                src="/assets/empty_bag_vetor.png"
                                alt="carrinho vazio"
                                className="empty-cart-image"
                            />
                            <div class="title">Sua sacola está vazia!</div>
                            <div class="subtitle">
                                Adicione algo para me fazer feliz :)
                            </div>
                        </div>
                    </div>
                    <div class="cart-blank-minimalist"></div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
    total: getCartTotal(
        state.cartList.cart,
        state.shipping.current,
        state.coupon.current
    ),
    subtotal: getCartTotal(state.cartList.cart),
    coupon: state.coupon.current,
    couponAmount: getCouponAmount(state.cartList.cart, state.coupon.current),
});

export default connect(mapStateToProps, {
    removeFromCart,
    incrementQty,
    decrementQty,
    setShipping,
})(CartPage);
