import React, { useEffect } from "react";

import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Currency from "../components/common/currency";
import "./styles_success.css";
import { useHistory } from "react-router-dom";
import Slip from "./Slip";
import DialogPix from "./DialogPix";
import DialogPaymentLink from "./DialogPaymentLink";

function OrderPage(props) {
    let history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function back() {
        history.push("/");
    }

    function isFreeShipping() {
        return (
            props.business.freeShipping &&
            props.business.freeShipping > 0 &&
            props.location.state.subTotal > props.business.freeShipping
        );
    }
    let isOnsiteCheckoutMP = function () {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.business.features &&
            props.business.features.includes("onsite-checkout-mp")
        );
    };

    const {
        items,
        orderTotal,
        address,
        payment,
        delivery,
        storeaddress,
        bankAccount,
        orderNumber,
        deliveryTax,
        coupon,
        couponAmount,
        subTotal,
        note,
        shippingDesc,
        shippingFee,
        cardLastDigits,
        cardImage,
        installmentsMessage,
        installmentRate,
        slip_url,
        initPoint,
        qrcode,
        expirationDatePix,
    } = props.location.state;
    /*
    const {
        items,
        orderTotal,
        address,
        payment,
        delivery,
        storeaddress,
        bankAccount,
        orderNumber,
        deliveryTax,
        coupon,
        couponAmount,
        subTotal,
        note,
        shippingDesc,
        shippingFee,
        cardLastDigits,
        cardImage,
        installmentsMessage,
        installmentRate,
        slip_url,
        initPoint,
        qrcode,
        expirationDatePix,
    } = {
        items: [],
        orderTotal: 10,
        address: "Endereco",
        payment: "payment-link",
        delivery: "takeout",
        storeaddress: "Store Address",
        bankAccount: "",
        orderNumber: "2345678",
        deliveryTax: "1",
        coupon: "",
        couponAmount: "",
        subTotal: 10,
        note: "Observação",
        shippingDesc: "2 dias",
        shippingFee: "",
        cardLastDigits: "",
        cardImage: "",
        installmentsMessage: "",
        installmentRate: "",
        slip_url: "https://google.com",
        initPoint: "https://mercadopago.com.br",
        qrcode: "fjdsl",
        expirationDatePix: Date.now() + MAX,
    };
    */

    let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    let current = new Date();
    const CheckDate = current.toLocaleDateString("pt-BR", options).toString();

    return (
        <div id="success-wrap">
            <div class="success-order" style={{ marginTop: 0 }}>
                <CheckCircleIcon
                    style={{ fontSize: 36, color: "rgba(var(--primary-color), 1)" }}
                />
                <h1>OBRIGADO!</h1>
                <h3>Recebemos o seu pedido e</h3>
                <h3>entraremos em contato em breve.</h3>
                <p>Número do pedido:</p>
                <span>{orderNumber}</span>
            </div>

            <div class="success-sumary">
                {payment === "slip" &&
                    slip_url &&
                    slip_url !== "" &&
                    isOnsiteCheckoutMP() && <Slip slip_url={slip_url} />}
                {payment === "money-order" &&
                    qrcode &&
                    qrcode !== "" &&
                    isOnsiteCheckoutMP() && (
                        <DialogPix
                            qrcode={qrcode}
                            expirationDatePix={expirationDatePix}
                        />
                    )}
                {payment === "payment-link" &&
                    initPoint &&
                    initPoint !== "" &&
                    isOnsiteCheckoutMP() && <DialogPaymentLink initPoint={initPoint} />}
                <div class="title">DETALHES DO PEDIDO</div>

                <div class="Rtable Rtable--3cols">
                    <div class="Rtable-cell Rtable-header">PRODUTO</div>
                    <div class="Rtable-cell Rtable-header">QTD</div>
                    <div class="Rtable-cell Rtable-header">PREÇO</div>
                    {items.map((item) => {
                        return (
                            <>
                                <div class="Rtable-cell Rtable-content">
                                    {item.name} {(item.color || item.size) && " - "}
                                    {item.color} {item.size}{" "}
                                    {item.variationValue
                                        ? " - " + item.variationValue
                                        : ""}
                                </div>
                                <div class="Rtable-cell Rtable-content">{item.qty}</div>
                                <div class="Rtable-cell Rtable-content">
                                    <Currency value={item.promoPrice || item.price} />
                                </div>
                            </>
                        );
                    })}
                    {coupon && couponAmount ? (
                        <>
                            <div class="Rtable-footer Rtable-header">SUBTOTAL</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={subTotal} />
                            </div>
                            <div class="Rtable-footer Rtable-header">
                                CUPOM({coupon.amount}%)
                            </div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={couponAmount} />
                            </div>
                        </>
                    ) : undefined}
                    {delivery === "delivery" ||
                    delivery === "localdelivery" ||
                    shippingFee ? (
                        <>
                            <div class="Rtable-footer Rtable-header">TAXA DE ENTREGA</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                {isFreeShipping() ? (
                                    <div>FRETE GRÁTIS</div>
                                ) : (
                                    <Currency value={deliveryTax || shippingFee} />
                                )}
                            </div>
                        </>
                    ) : undefined}
                    {isOnsiteCheckoutMP() && installmentRate && installmentRate > 0 ? (
                        <>
                            <div class="Rtable-footer Rtable-header">JUROS</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={installmentRate} />
                            </div>
                        </>
                    ) : undefined}
                    <div class="Rtable-footer Rtable-header">TOTAL</div>
                    <div class="Rtable-footer"></div>
                    <div class="Rtable-footer Rtable-total">
                        <Currency value={orderTotal} />
                    </div>
                </div>
            </div>

            <div class="success-details">
                <div class="title">RESUMO</div>
                <p>Número do pedido: {orderNumber}</p>
                <p>Data: {CheckDate}</p>
                <p>
                    Total:
                    <Currency value={orderTotal} />
                </p>
            </div>
            {delivery === "delivery" && !shippingFee ? (
                <div class="success-delivery">
                    <div class="title">ENDEREÇO DE ENTREGA</div>
                    <p>{address}</p>
                </div>
            ) : undefined}
            {delivery === "takeout" ? (
                <div class="success-delivery">
                    <div class="title">RETIRAR NA LOJA</div>
                    <p>{storeaddress}</p>
                </div>
            ) : undefined}

            {delivery === "delivery" &&
            shippingDesc &&
            shippingFee &&
            !isFreeShipping() ? (
                <div class="success-delivery">
                    <div class="title">ENDEREÇO DE ENVIO</div>
                    <p>{address}</p>
                </div>
            ) : undefined}

            {delivery === "delivery" && shippingDesc && !isFreeShipping() ? (
                <div class="success-shipping">
                    <div class="title">DADOS DO ENVIO</div>
                    <p>{shippingDesc}</p>
                </div>
            ) : undefined}

            <div class="success-payment">
                <div class="title">FORMA DE PAGAMENTO</div>
                <p>{payment === "debit-card" ? "Cartão de débito" : ""}</p>
                <p>{payment === "credit-card" ? "Cartão de crédito" : ""}</p>

                {payment === "credit-card" && isOnsiteCheckoutMP() ? (
                    <div>
                        <img src={cardImage} alt="" />
                        <p>{cardLastDigits}</p>
                        <p>{installmentsMessage}</p>
                    </div>
                ) : undefined}

                <p>{payment === "money" ? "Dinheiro" : ""}</p>
                <p>{payment === "money-order" ? "Transferência Bancária / PIX" : ""}</p>
                <p>{payment === "slip" ? "Boleto Bancário" : ""}</p>
                <p>{payment === "payment-link" ? "Link de Pagamento" : ""}</p>
                {payment === "money-order" &&
                bankAccount !== null &&
                bankAccount !== undefined ? (
                    <div className="col-sm-12 mt-2 bank-section">
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    bankAccount.indexOf("\n") !== -1
                                        ? bankAccount.split("\n").join("<br/>")
                                        : bankAccount,
                            }}
                        />
                    </div>
                ) : undefined}
            </div>
            {note && (
                <div class="success-note">
                    <div class="title">OBSERVAÇÃO</div>
                    <p>{note}</p>
                </div>
            )}
            <div class="success-buttons">
                <div
                    onClick={() => back()}
                    className={
                        props.business.layout === "minimalist"
                            ? "success-button-minimalist"
                            : "success-button-food"
                    }
                >
                    <div className="start">INÍCIO</div>
                </div>
            </div>

            <div className="copyright">
                <img src={"/assets/logocarty126x48.png"} alt="carty logo" />
                <span>Crie hoje sua loja virtual</span>
                <span>www.carty.com.br</span>
            </div>

            <div>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                ></link>
                <a
                    href={
                        "https://api.whatsapp.com/send?phone=55" +
                        props.business.whatsappNumber
                    }
                    class="float"
                >
                    <i class="fa fa-whatsapp my-float"></i>
                </a>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(OrderPage);
