import React from "react";
import Carousel from "react-material-ui-carousel";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { filterCategory } from "../actions";

function Banner(props) {
    let history = useHistory();

    function navigateToPromoProducts({ name, bannerUri }) {
        let promoCode = name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "");
        props.filterCategory(promoCode);

        history.push({ pathname: "/category", state: { bannerUri: bannerUri } });
    }

    return (
        <>
            {props.business?.features?.includes("promo") && props.promos && props.promos.filter((a) => a.activated).length > 0 && (
                <div className="promo-banner-container">
                    <Carousel autoPlay={true} interval={5000} indicators={false}>
                        {props.promos &&
                            props.promos
                                .filter((a) => a.activated)
                                .map((item, i) => (
                                    <PromoItem
                                        key={i}
                                        item={item}
                                        callback={navigateToPromoProducts}
                                    />
                                ))}
                    </Carousel>
                </div>
            )}
        </>
    );
}

function PromoItem(props) {
    return (
        <>
            <div onClick={() => props.callback(props.item)}>
                <img
                    src={props.item.bannerUri}
                    alt="banner"
                    style={{
                        objectFit: "contain",
                        width: "100%",
                    }}
                />
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    promos: state.promos.current,
});

export default connect(mapStateToProps, { filterCategory })(Banner);
