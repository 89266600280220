import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Currency from "../../components/common/currency";
import { removeFromCart, incrementQty, decrementQty } from "../../actions";
import { getCartTotal, getCouponAmount } from "../../services";
import "./styles_f.css";
import { loadBusiness } from "../../api/products";
import store from "../../store";
import { getBusiness } from "../../actions";
import Coupon from "../Coupon";
import { setShipping } from "../../actions";
import Snackbar from "@material-ui/core/Snackbar";

function CartPage(props) {
    const [message, setMessage] = useState("");
    useEffect(() => {
        async function init() {
            let pathname = props.location.pathname;
            let uid = pathname.split("/")[1];
            let business = await loadBusiness(uid);
            store.dispatch(getBusiness(business));
        }
        if (!props.business.uid) {
            init();
        }
    }, [props.business.uid, props.location.pathname]);

    function decrement(item) {
        if (item.qty === 1) {
            props.removeFromCart(item);
        } else {
            props.decrementQty(item);
        }
        props.setShipping({});
    }

    function increment(item) {
        if (showStockQuantity() && item.qty >= item.quantity) {
            return;
        }
        props.incrementQty(item, 1);
        props.setShipping({});
    }

    function checkout() {
        if (props.business.minimumOrder && props.business.minimumOrder > 0) {
            if (props.total < props.business.minimumOrder) {
                setMessage(
                    `O pedido mínimo é de R$ ${props.business.minimumOrder}. Qualquer dúvida entre em contato conosco.`
                );
                return;
            }
        }
        props.history.push({
            pathname: "/checkout",
        });
    }

    function showStockQuantity() {
        let show = false;
        if (
            props.business.activePlanName &&
            props.business.activePlanName !== "Gratuito" &&
            props.business.features &&
            props.business.features.includes("stock")
        ) {
            show = true;
        }

        return show;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function handleClose() {
        setMessage("");
    }

    return (
        <div id="cart-wrap">
            <div class="cart-header">
                <img
                    src="../assets/blank_image_top.png"
                    alt="carty icon"
                    width="50"
                    height="50"
                />
            </div>
            <div class="cart-header-title">
                <div class="title">{props.business.name}</div>
            </div>
            {props.cartItems.length > 0 ? (
                <>
                    <div class="cart-items">
                        <ul>
                            <li>PRODUTO</li>
                            <li>QUANTIDADE</li>
                            <li>PREÇO</li>
                        </ul>
                        <div class="line"></div>

                        {props.cartItems.map((item) => {
                            return (
                                <>
                                    <div class="item">
                                        <div class="item-picture">
                                            <img src={item.img} alt="img1" />
                                        </div>

                                        <div class="item-desc">
                                            <div class="item-name">
                                                {item.name}{" "}
                                                {(item.color || item.size) && " - "}
                                                {item.color} {item.size}
                                            </div>
                                            {item.variationValue && (
                                                <div class="item-variation">
                                                    {item.variationValue}
                                                </div>
                                            )}
                                            <div class="item-price">
                                                <Currency
                                                    value={
                                                        item.promoPrice
                                                            ? item.promoPrice
                                                            : item.price
                                                    }
                                                />
                                            </div>
                                            <div class="item-quantity">
                                                <button
                                                    class="minus"
                                                    onClick={() => decrement(item)}
                                                >
                                                    -
                                                </button>
                                                <div class="qty">{item.qty}</div>
                                                <button
                                                    class="add"
                                                    onClick={() => increment(item)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            {showStockQuantity() && (
                                                <div class="item-name">
                                                    Disponível: {item.quantity}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                </>
                            );
                        })}
                    </div>

                    <div class="cart-shipping-food">
                        
                    </div>

                    <Coupon />

                    <div class="item-footer">
                        <div>
                            {props.couponAmount && <p>Sub Total</p>}
                            {props.couponAmount && <p>Cupom ({props.coupon.amount}%)</p>}
                        
                            <p>Total</p>
                        </div>
                        <div>
                            {props.couponAmount && (
                                <p>
                                    <Currency value={props.subtotal} />
                                </p>
                            )}
                            {props.couponAmount && (
                                <p>
                                    <Currency value={props.couponAmount} />
                                </p>
                            )}
                          
                            <p>
                                {props.couponAmount ? (
                                    <Currency
                                        value={
                                            props.subtotal -
                                            props.couponAmount
                                            
                                        }
                                    />
                                ) : (
                                    <Currency value={props.total} />
                                )}
                            </p>
                        </div>
                    </div>

                    <div class="buttons">
                        <Link
                            to={{
                                pathname: `${process.env.PUBLIC_URL}/`,
                                state: { action: "back" },
                            }}
                            class="secondary"
                        >
                            VOLTAR
                        </Link>
                        <div onClick={() => checkout()} class="primary">
                            PRÓXIMO
                        </div>
                    </div>
                    <div class="blank"></div>
                    <Snackbar
                        open={message !== ""}
                        //onClose={handleClose}
                        message={message}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        autoHideDuration={3000}
                        onClose={handleClose}
                    />
                </>
            ) : (
                <>
                    <div className="cart-items">
                        <h3>Opa! Carrinho vazio.</h3>
                        <span>Que tal dar uma olhada nos nossos produtos?</span>
                    </div>
                    <div class="buttons">
                        <Link
                            to={{
                                pathname: `${process.env.PUBLIC_URL}/`,
                                state: { action: "back" },
                            }}
                            class="secondary"
                        >
                            VOLTAR
                        </Link>
                        <Link class="disabled">PRÓXIMO</Link>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
    total: getCartTotal(
        state.cartList.cart,
        state.shipping.current,
        state.coupon.current
    ),
    subtotal: getCartTotal(state.cartList.cart),
    shipping: state.shipping.current,
    coupon: state.coupon.current,
    couponAmount: getCouponAmount(state.cartList.cart, state.coupon.current),
});

export default connect(mapStateToProps, {
    removeFromCart,
    incrementQty,
    decrementQty,
    setShipping,
})(CartPage);
