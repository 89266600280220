export const generateTransformationURL = (url, mode, imageTransformation) => {

    if (url && url.indexOf('http') === -1) {
      return url;
    }
  
    const isNewRepository = url.indexOf("imagedelivery") !== -1; 
    if ( isNewRepository ) {
      return generateTransformationURLCloudflare(url, mode);
    } else {
      return generateTransformationURLCloudinary(url, mode, imageTransformation);
    }
  }
  
  
  export const generateTransformationURLCloudflare = (url, mode) => {
    if (mode === "original") {
      return url;
    } else if (mode === "4:3") {
      return url.replace('public', 'portrait');
    } else if (mode === "1:1") {
      return url.replace('public', 'square');
    } else if (mode === "thumbnail") {
      return url.replace('public', 'thumbnail');
    }
    return url;
  };
  export const generateTransformationURLCloudinary = (url, mode, imageTransformation) => {
    let result = "";
  
    let transformation = "t_normal/";
    if (mode === "original") {
        if (imageTransformation === "high") {
            transformation = "t_original/";
        } else {
            transformation = "t_normal/";
        }
    } else if (mode === "4:3") {
        if (imageTransformation === "high") {
            transformation = "t_portrait/";
        } else {
            transformation = "t_quality/";
        }
    } else if (mode === "1:1") {
        if (imageTransformation === "high") {
            transformation = "t_square/";
        } else {
            transformation = "t_square-eco/";
        }
    } else if ( mode === "thumbnail") {
        transformation = "t_thumbnail/";
    }
    if (url !== null ) {
        const prefix = url.substring(0, url.indexOf("upload/") + 7);
        const sufix = url.substring(url.indexOf("upload/") + 7, url.length);
        result = prefix + transformation + sufix;
        return result;
    } 
    return url;
  };


export const generateIco = (url) => {
    //console.log(url);
    if (url) {
        let result = "";
        let transformation = "w_90,c_scale/";

        const prefix = url.substring(0, url.indexOf("upload/") + 7);
        const sufix = url.substring(url.indexOf("upload/") + 7, url.length);
        result = prefix + transformation + sufix;
        return result;
    }
    return url;
};
