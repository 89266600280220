import React, { useState, useEffect } from "react";
import service from "../api/service";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { makeStyles } from "@material-ui/core/styles";
import { setShipping } from "../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCartTotal } from "../services";

function Shipping(props) {
    const [destinationZipCode, setDestinationZipCode] = useState();
    const [localShipping, setLocalShipping] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const [isEligibleFreeShipping] = useState(
        props.business.freeShipping && props.business.freeShipping > 0
    );
    function isFreeShipping() {
        return isEligibleFreeShipping && props.subtotal > props.business.freeShipping;
    }
    function isShipping() {
        //console.log(props.business.activePlanName);
        return (
            props.business &&
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Diamante") &&
            props.business.delivery &&
            props.business.delivery.includes("shipping")
        );
    }

    function isME() {
        return (
            props.business &&
            (props.business.activePlanName === "Pro" || props.business.activePlanName === "Diamante") &&
            props.business.features &&
            props.business.features.includes("me")
        );
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        //console.log("aqui");
        //console.log(props.shipping);
        if (isEmpty(props.shipping)) {
            //console.log("entrei");
            setLocalShipping(null);
        }
    }, [props.shipping]);

    useEffect(() => {
        if (!isFreeShipping()) {
            //console.log("props.searchBy", props.searchBy);
            if (props.searchBy && props.searchBy.length === 8) {
                //console.log("props.searchBy");
                //console.log(props.searchBy);
                //console.log("vai calcular");
                calcShipping(props.searchBy);
                setDestinationZipCode(props.searchBy);
            }else {
                props.setShipping({});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchBy]);

    function handleClick(m) {
        setMessage(m);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    const useStyles = makeStyles((theme) => ({
        root: { padding: 5 },
        label: {
            fontSize: 14,
            marginLeft: 10,
        },
        loading: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
        wrapper: {
            position: "relative",
        },
        buttonProgress: {
            color: "gray ",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        buttonProgressCheckout: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    }));

    const classes = useStyles();

    async function calcShipping(searchBy) {
        setLoading(true);
        props.setShipping({});
        let ids = props.cartItems.map((item) => {
            let a = [];
            let i = 0;
            while (i < item.qty) {
                a.push(item.id);
                i++;
            }
            return a;
        });
        //console.log(ids.join());
        ids = ids.join();
        if ((destinationZipCode && destinationZipCode !== "") || searchBy) {
            let zip = searchBy || destinationZipCode;
            let originZipCode = props.business.originZipCode;
            zip = zip.replace("-", "");

            try {
                let url = "";
                if (isME()) {
                    let shippingCompanies = props.business.shippingCompanies.join();
                    url = `/shipping/me/all/${props.business._id}/${ids}/${originZipCode}/${zip}/${shippingCompanies}`;
                } else {
                    url = `/shipping/me/${ids}/${originZipCode}/${zip}`;
                }
                //console.log(url);
                const response = await service.get(url);
                //console.log(response);
                if (response.data.status !== 400) {
                    if (isME()) {
                        const unavailable = response.data.filter((item) => {
                            return item.price === 0;
                        });
                        const available = response.data.filter((item) => {
                            return item.price !== 0;
                        });
                        let sorted = [...available, ...unavailable];

                        setLocalShipping(sorted);
                    } else {
                        setLocalShipping(response.data);
                    }
                } else {
                    handleClick("Não foi possível calcular o frete");
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                //console.log(e);
                if (e === "Invalid zip code") {
                    handleClick("CEP inválido");
                } else if (e === "Server error") {
                    handleClick("Não foi possível calcular o frete");
                } else {
                    handleClick("Não foi possível calcular o frete");
                }
            }
        } else {
            handleClick("Digite o CEP para consulta.");
        }
        setLoading(false);
    }
    function handleShipping(method) {
        let result = {
            ...localShipping,
            method: method,
            total:
                method === "PAC"
                    ? parseFloat(localShipping.pacPrice.replace(",", "."))
                    : parseFloat(localShipping.sedexPrice.replace(",", ".")),
            days: method === "PAC" ? localShipping.pacDays : localShipping.sedexDays,
            destinationZipCode: destinationZipCode,
        };

        props.setShipping(result);
    }

    function handleME(service) {
        //console.log("handleME", service);
        let result = {
            ...service,
            method: service.name,
            total: parseFloat(service.price.replace(",", ".")),
            days: service.days,
            destinationZipCode: destinationZipCode,
        };
        //console.log(result);
        props.setShipping(result);
    }

    return isShipping() || isME() ? (
        <>
            <div class="cart-shipping">
                {isFreeShipping() ? (
                    <div class="free-shipping">
                        <span role="img" aria-label="party">
                            🎉
                        </span>
                        Parabéns! Você ganhou FRETE GRÁTIS!
                        <span role="img" aria-label="party">
                            🎉
                        </span>
                    </div>
                ) : (
                    props.searchBar && (
                        <>
                            <InputMask
                                mask="99999-999"
                                value={destinationZipCode}
                                onChange={(e) => setDestinationZipCode(e.target.value)}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        label="Consulte o Frete:"
                                        placeholder="Insira aqui o seu CEP"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        shrink={false}
                                        style={{ flex: 0.8 }}
                                    />
                                )}
                            </InputMask>

                            <div onClick={() => calcShipping()} class="apply-button">
                                <div>CALCULAR</div>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </>
                    )
                )}
            </div>
            {localShipping && isME() ? (
                <div class="cart-shipping-results">
                    <RadioGroup aria-label="shipping-method" name="shipping-method">
                        {localShipping &&
                            localShipping.map((item) => (
                                <FormControlLabel
                                    value={item.name}
                                    control={
                                        <Radio
                                            icon={
                                                <RadioButtonUncheckedIcon
                                                    style={{ fontSize: 17 }}
                                                />
                                            }
                                            checkedIcon={
                                                <RadioButtonCheckedIcon
                                                    style={{ fontSize: 17 }}
                                                />
                                            }
                                            disabled={item.price === 0}
                                            classes={{
                                                root: classes.root,
                                            }}
                                            checked={
                                                props.shipping &&
                                                props.shipping.method === item.name
                                            }
                                            onClick={() => handleME(item)}
                                        />
                                    }
                                    label={
                                        item.price !== 0
                                            ? item.name +
                                              " : R$ " +
                                              item.price +
                                              " (" +
                                              item.days +
                                              ") "
                                            : item.name
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                />
                            ))}
                    </RadioGroup>
                </div>
            ) : localShipping && isShipping() ? (
                <div class="cart-shipping-results">
                    <RadioGroup aria-label="shipping-method" name="shipping-method">
                        <FormControlLabel
                            value="PAC"
                            control={
                                <Radio
                                    icon={
                                        <RadioButtonUncheckedIcon
                                            style={{ fontSize: 17 }}
                                        />
                                    }
                                    checkedIcon={
                                        <RadioButtonCheckedIcon
                                            style={{ fontSize: 17 }}
                                        />
                                    }
                                    disabled={localShipping.pacPrice === 0}
                                    classes={{
                                        root: classes.root,
                                    }}
                                    checked={
                                        props.shipping && props.shipping.method === "PAC"
                                    }
                                    onClick={() => handleShipping("PAC")}
                                />
                            }
                            label={
                                localShipping.pacPrice === 0
                                    ? "PAC : " + localShipping.pacDesc
                                    : "PAC : " +
                                      localShipping.pacDesc +
                                      " (" +
                                      localShipping.pacDays +
                                      ") "
                            }
                            classes={{
                                label: classes.label,
                            }}
                        />

                        <FormControlLabel
                            value="SEDEX"
                            control={
                                <Radio
                                    icon={
                                        <RadioButtonUncheckedIcon
                                            style={{ fontSize: 17 }}
                                        />
                                    }
                                    checkedIcon={
                                        <RadioButtonCheckedIcon
                                            style={{ fontSize: 17 }}
                                        />
                                    }
                                    classes={{
                                        root: classes.root,
                                    }}
                                    disabled={localShipping.sedexPrice === 0}
                                    checked={
                                        props.shipping &&
                                        props.shipping.method === "SEDEX"
                                    }
                                    onClick={() => handleShipping("SEDEX")}
                                />
                            }
                            label={
                                localShipping.sedexPrice === 0
                                    ? "SEDEX : " + localShipping.sedexDesc
                                    : "SEDEX : " +
                                      localShipping.sedexDesc +
                                      " (" +
                                      localShipping.sedexDays +
                                      ") "
                            }
                            classes={{
                                label: classes.label,
                            }}
                        />
                    </RadioGroup>
                </div>
            ) : (
                loading &&
                props.searchBy && (
                    <div style={{ position: "relative" }}>
                        <CircularProgress
                            size={30}
                            className={classes.buttonProgressCheckout}
                        />
                    </div>
                )
            )}
            <Snackbar
                open={open}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                onClose={handleClose}
            />
        </>
    ) : (
        <></>
    );
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    business: state.business.current,
    shipping: state.shipping.current,
    total: getCartTotal(state.cartList.cart, state.shipping.current),
    subtotal: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, { setShipping })(Shipping);
